$brand-blue: #000099;
$primary-black: #000026;
$brand-dark-blue: #00004D;
$brand-sea-green: #40AFCE;
$brand-dark-green: #00506B;
$primary-grey: #717171;
$brand-orange: #EE6352;
$brand-light-orange: #FFDDE0;
$brand-black: #00080B;
$brand-sea-green-light: #E0FBFC;
$brand-warm-grey: #FCFDF7;
$brand-white: #FCFDFE;
$brand-burnt-white: #dcdcdc;
$brand-dark-sea-green : #C2DFE3;
$brand-sea-blue: #C1F0FF;
$brand-sea-blue-dark:#58A3BD;