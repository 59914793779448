@use "./typography" as typo;
@use "./colors" as colors;
@use "./variables" as var;


.brand-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;

    

    @media (min-width: 576px) {
        max-width: 54rem;
    }

    @media (min-width: 768px) {
        max-width: 83rem;
        padding: 0 1.2rem
    }

    @media (min-width: 992px) {
        max-width: 96rem;
    }

    @media (min-width: 1200px) {
        max-width: 120rem;
    }
}

.brand-container-2 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    width: 100%;


    @media (min-width: 1200px) {
        max-width: 82.4rem;
    }
}

.brand-shadow-1 {
    box-shadow: var.$primary-box-shadow;
}

.brand-drop-shadow-1 {
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
}


.d-flex {
    display: flex;
}

.al-items {
    align-items: center;
}

.js-center {
    justify-content: center;
}

.js-between {
    justify-content: space-between;
}

.js-evenly {
    justify-content: space-evenly;
}



.p-fixed {
    position: fixed;
}

.bt-0 {
    bottom: 0;
}

.lft-0 {
    left: 0;
}

.rgt-0 {
    right: 0;
}

//font-weight
.font-350 {
    font-weight: 350;
}

.font-400 {
    font-weight: 400;
}


//typography helpers
.big-heading-1 {
    font-size: clamp(4rem, 6vw, typo.$fz-heading-1);
    // font-size: typo.$fz-heading-1;
    font-family: "librefranklin-extrabold", sans-serif;
    color: colors.$brand-blue;
    font-weight: 900;
    letter-spacing: 1.5%;
    line-height: 7.8rem;

    @media(max-width: 768px){
        line-height: 6.0rem;
    }
}

.big-heading-2 {
    font-size: typo.$fz-heading-2;
    font-weight: 800;
    color: colors.$brand-blue;
    letter-spacing: 6%;
}

.big-heading-3 {
    font-size: typo.$fz-heading-3;
    letter-spacing: 1.5%;
    color: colors.$brand-blue;
    line-height: 3;
}

.big-heading-4 {
    font-size: typo.$fz-heading-4;
    color: colors.$brand-blue;
    letter-spacing: 1.5%;
    font-weight: 550;
    line-height: 3;
}

.big-heading-5 {
    font-size: typo.$fz-heading-5;
    color: colors.$brand-dark-blue;
    letter-spacing: 1.5%;
    font-weight: 500;
    line-height: 2;
}

.heading-support-text {
    font-size: clamp(3rem, 3vw, typo.$fz-xxl-2);
    line-height: 8rem;
    font-weight: 300;

    @media(max-width: 1280px) {
        max-width: 70rem;
    }

    @media(max-width: 768px){
        line-height: 4rem;
    }
}

.large-text-writeup {
    font-size: clamp(3rem, 4vw, typo.$fz-xxl-2);
    max-width: calc((102.6rem / 120rem) * 100%);
    margin: 0 auto;

    @media(max-width:768px){
        font-size: clamp(2rem, 4vw, typo.$fz-xxl-2);
    }


}

.large-caption {
    font-size: typo.$fz-sm;
    color: colors.$primary-grey;
    letter-spacing: 0;
    font-weight: 550;
    line-height: 2;
}

.margin-top-64 {
    margin-top: 6.4rem;
}

.margin-bottom-64 {
    margin-bottom: 6.4rem;
}

.largest-padding {
    padding: 6rem 0 6.4rem;

    @media(max-width:768px) {
        padding: 4rem 0 4.4rem;
    }
}

.mr-2{
    margin-right: 24px;
}



.line-height-xl {
    line-height: 4.8rem;
}


.text-center {
    text-align: center;
}

.text-bold-xl {
    font-size: typo.$fz-xl;
    font-weight: 600;
    color: colors.$brand-dark-blue;
}

.center-nested-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-none {
    display: none;
}

.form-label {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: typo.$fz-sm;
    color: colors.$brand-dark-blue;
    text-transform: capitalize;
    font-family: inherit;
    line-height: 2.4rem;
}

.full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

/**
 * FLUID TYPE
 *
 * Scale text based on viewport and the following variables. Props should be unitless, but will be converted
 * to REM units. E.G '1' will be converted to '1rem'
 *
 * --fluid-type-min-size: The smallest font size. Default 1
 * --fluid-type-max-size: The largest font size: Default 2
 * --fluid-type-min-screen: The smallest viewport size that this works for: Default 20
 * --fluid-type-max-screen: The largest viewport size that this works for: Default 88
 */
.fluid-type {
    --fluid-type-min-size: 1;
    --fluid-type-max-size: 2;
    --fluid-type-min-screen: 20;
    --fluid-type-max-screen: 88;

    /* We multiply by 1rem to essentially stick a rem unit to a number. */
    font-size: calc((var(--fluid-type-min-size) * 1rem) + (var(--fluid-type-max-size) - var(--fluid-type-min-size)) * (100vw - (var(--fluid-type-min-screen) * 1rem)) / (var(--fluid-type-max-screen) - var(--fluid-type-min-screen)));
}


.justify-text{
    text-align: justify;
}

.link{
    color:blue;
    text-decoration: underline;
}




/*
* SET LOCKS ON ELEMENTS
*/
// h1.fluid-type {
//     --fluid-type-min-size: 2;
//     --fluid-type-max-size: 4;
// }

// h2.fluid-type {
//     --fluid-type-min-size: 1.5;
//     --fluid-type-max-size: 2.2;
// }