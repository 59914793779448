@use "styles/colors" as colors;
@use "styles/variables" as var;


.valorise-header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 9.6rem;
    position: relative;
    margin-bottom: -10rem;

    // &.header-nav--scrolled {
    //     background-color: colors.$brand-white;
    //     box-shadow: var.$primary-box-shadow;
    //     transition: background-color .35s ease-in;
    // }

    @media(max-width: 1024px) {
        padding: 2rem 4rem;
    }
}

.header-right__nav {
    display: flex;
    align-items: center;

    color: colors.$brand-dark-blue;

    &>* {
        margin-left: 3.27rem;
    }

    @media (max-width: 1000px) {
        display: none;
    }
}

.goto-home__link>img {
    @media(max-width:1024px) {
        max-width: 10rem;
    }
}

//dropdown

.drop-down {
    position: relative;
}

.drop-down__items {
    position: absolute;
    left: 0;
    right: 0;
    top: 3rem;
    background-color: colors.$brand-white;
    box-shadow: var.$primary-box-shadow;
    border-radius: 4px;
    min-width: max-content;
    z-index: 9999;
    color: inherit;

    &>ul {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        &>li {
            display: flex;
            flex-direction: column;
            // border-bottom: 1px solid lighten(colors.$primary-grey, 50);

            &>a {
                padding: 1rem;
                transition: color .3s ease-in;

                &:hover {
                    color: colors.$brand-blue;
                    font-weight: 600;
                }
            }
        }


    }
}

.drop-down__select {
    display: flex;
    align-items: center;
    cursor: pointer;


    &>button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1.6rem;
        background-color: transparent;
        border: 1px solid transparent;
        outline: none;
        font-size: inherit;
        font-weight: unset;
        color: inherit;

        &>span {
            font-weight: 300;
        }

        &>img {
            margin-left: 1rem;
        }
    }
}

.header-link>a {
    font-weight: 300;

}

.rotate-down {
    transform: rotate(180deg);
}

.rotate-up {
    transform: rotate(0);
}


.header-right__nav__mobile{
    display:none
}


@media only screen and (max-width:768px) {
    .header-right__nav__mobile{
        display:flex;
        flex-direction: column;
        width: 100%;
        margin-top:12%;
        padding: 20px;
        background-color: colors.$brand-white;
    }
    .drop-down{
        padding-bottom: 20px;
    }

    .header-link{
        padding-left: 19px;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width:576px){
    .header-right__nav__mobile{
        margin-top: 30%;
    }
}