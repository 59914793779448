@use "./colors" as colors;

img {
    display: block;
    max-width: 100%;
}

a,
button,
textarea,
input {
    font-family: inherit;
}

a,
button {

    color: colors.$brand-black;
    cursor: pointer;
}

a {
    text-decoration: none;
}

ul li,
ol li {
    list-style: none;
}