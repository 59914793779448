@use "./typography";
@use "./colors" as colors;

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    background: colors.$brand-warm-grey;
    font-family: "Libre Franklin", sans-serif;

    line-height: 1.5;
    font-size: 1.6rem;
    font-weight: 200;
    color: colors.$primary-black;

    overflow-x: hidden;
}